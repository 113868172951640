
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DialogLayout from './DialogLayout.vue';
import { QuickDialog, AlertModel } from '../store/models/DialogModel';
import { Item } from '../store/models/ItemModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import AlertComponent from '@/components/AlertLayout.vue';
import { BuildItemAlertMessage } from '@/helpers/ObjectHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';

@Component({
  components: { DialogLayout, AlertComponent }
})
export default class QuickItemHoldDialog extends Vue {
  $refs!: {
    Deleteinputs: HTMLFormElement[];
  };
  @Prop({ required: true }) item!: Item;
  /* Store Actions */

  @Getter('getPosAssociatedWithItem', { namespace: 'items' })
  getAssociatedPos: any;

  dialog: boolean = false;
  data: QuickDialog | null = null;
  input: string = '';
  backspaceCounter: number = 0;
  currentIndex: number = 0;
  openAlert: boolean = false;
  alertOption!: AlertModel;
  isDeleting: boolean = false;
  onTextBoxFocus(index: number, e: EventListener) {
    const input = document.getElementById(`delete-input-${index}`) as HTMLInputElement;

    if (input && input.value) {
      input.select();
    }
  }

  removeInputFromTextField(index: number) {
    const input = document.getElementById(`delete-input-${index}`) as HTMLInputElement;
    if (input && input.value) {
      input.select();
      setTimeout(() => {
        this.$refs.Deleteinputs[index - 1].$refs.input.value = '';
      }, 10);
    }
    this.input = this.setCharAt(this.input, index - 1, '');
  }

  onkeydown(e: any, i: number) {
    if (e !== '') {
      this.populate(i);
    }
  }
  onBackspacePress(e: any, index: number) {
    if (index >= 1) {
      let hasInput = this.$refs.Deleteinputs[index - 1].$refs.input.value;
      if (!hasInput) {
        this.removeInputFromTextField(index - 1);
        this.input = this.setCharAt(this.input, index - 2, '');
      } else {
        this.input = this.setCharAt(this.input, index - 1, '');
      }
      setTimeout(() => {
        this.focusOn(index - 2);
      }, 100);
    }
    if (this.backspaceCounter == 2) this.backspaceCounter = 0;
  }
  populate(index: number) {
    this.input = this.setCharAt(
      this.input,
      index - 1,
      this.$refs.Deleteinputs[index - 1].$refs.input.value
    );

    if (index <= 5) {
      this.focusOn(index);
    }
  }

  focusOn(index: number) {
    this.$refs.Deleteinputs[index].focus();
  }

  setCharAt(str: string, index: number, chr: string) {
    if (index > str.length) index = 0;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }
  handleAlertConfirmation() {
    this.openAlert = false;
    this.$emit('response', true);
  }
  onDeleteClick() {
    if (this.input.toLocaleLowerCase() == 'delete') {
      if (this.getAssociatedPos[0]) {
        this.openAlert = true;
        return;
      }
      this.$emit('response', true);
      this.isDeleting = true;
    } else {
      SetSnackBar('Type delete in order to delete this item.');
    }
  }
  closeAlert() {
    this.openAlert = false;
  }
  closeDialog() {
    this.openAlert = false;
    this.isDeleting = false;
    this.dialog = false;
  }

  async openDialog() {
    this.dialog = true;
    await this.$store.dispatch('items/checkForAssociatedOrderlineByItemId', this.item.Id);
    if (this.getAssociatedPos[0]) {
      this.alertOption = BuildItemAlertMessage(
        'delete',
        this.item,
        this.getAssociatedPos
      );
    }
    this.data = ConversionHelper.convertQuickDialog(this.item);
    setTimeout(() => {
      this.$refs.Deleteinputs[0].focus();
    }, 1);
  }

  /* Computed*/
  get readyToDelete() {
    return this.input.toLocaleLowerCase() == 'delete' && !this.openAlert;
  }
}
