
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { Item } from '@/store/models/ItemModel';
import { ItemService } from '@/services/item-service';
import { OrdersService } from '@/services/orders-service';
import { QuoteService } from '@/services/quote-service';
import { CustomerService } from '@/services/customer-service';
import DialogLayout from './DialogLayout.vue';
import { QuickDialog } from '@/store/models/DialogModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { CustomerOrder, RushOption } from '@/store/models/OrderlineModel';
import { PricingMatrixModel, QuoteData } from '../store/models/QuotesModel';
import AddressSelector from './AddressSelector.vue';
import J1LTextFieldNumber from '@/components/vuetifyOverrides/J1LTextFieldNumber.vue';
import { DateHelper } from '@/helpers/DateHelper';
import { Customer } from '@/store/models/CustomerModel';
import AddEditBillingAddress from './addressComponents/AddEditBillingAddress.vue';
import { Wait } from '@/helpers/WebHelper';

@Component({
  components: { DialogLayout, AddressSelector, J1LTextFieldNumber, AddEditBillingAddress }
})
export default class ItemAddToCart extends Vue {
  $refs!: {
    addressSelector: HTMLFormElement;
    J1LTextFieldNumber: HTMLFormElement;
    BillingAddressForm: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) item!: Item;

  /* Store Actions */
  @Getter('getShoppingCart', { namespace: 'orderlines' })
  shoppingCart!: CustomerOrder;
  @Getter('getCustomer', { namespace: 'customer' })
  customer!: Customer;
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomer!: any;
  @Action('CalculatePriceOfShoppingCart', { namespace: 'orderlines' })
  calculatePriceOfShoppingCart!: any;
  @Action('RemoveFromShoppingCart', { namespace: 'orderlines' })
  RemoveFromShoppingCart!: any;
  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: any) {
    if (!val) {
      this.dialog = false;
    }
  }
  @Watch('quantity')
  onQuantityChange(val: number) {
    if (val) {
      this.waitForSearch();
    }
  }
  /* Data */
  dialog: boolean = false;
  data: QuickDialog | null = null;
  rushOption: RushOption[] = [];
  isLoading: boolean = false;
  quantity: number | null = null;
  selectedItem!: Item;
  totalPrice: string = '$0.00';
  selectedPO: any = '';
  entries: any[] = [];
  descriptionLimit = 50;
  addresses: any[] = [];
  selectedAddress: string = '';
  typingTimer: any;
  isFormValid: boolean = false;
  /* Methods */
  async waitForSearch() {
    await this.GetQuickQuoteValues();
  }
  async onAddressUpdated() {
    this.getCurrentCustomer();
    this.$refs.BillingAddressForm.closeDialog();
  }
  addToCart() {
    if (this.isFormValid) {
      if (!this.customer.Addresses[0]) {
        this.$refs.BillingAddressForm.openAddressDialog(false);
        return;
      }
      this.$emit('addToCart', {
        quantity: this.quantity,
        shippingAddressId: this.selectedAddress
      });
      this.closeDialog();
    }
  }
  async GetQuickQuoteValues() {
    this.isLoading = true;
    this.typingTimer = DateHelper.Debounce(async () => {
      let quantity = Number(this.quantity);
      let newOrderline = ConversionHelper.generateNewOrderlineModel(
        this.item.Id,
        quantity,
        this.selectedAddress
      );
      const { data } = await OrdersService.GenerateOrderlineDefaults(newOrderline);
      await this.calculatePriceOfShoppingCart([data]);

      let totalprice = this.shoppingCart.Orderlines.find(ol => ol.Id == data.Id)!
        .ExtendedPrice;
      this.totalPrice = totalprice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      this.removeTempOrderline();
      this.isLoading = false;
    }, this.typingTimer);
  }
  async getItemDetails() {
    const response = await ItemService.GetItemDetails(this.item.Id);
    this.selectedItem = new Item(response.data);
  }
  async removeTempOrderline() {
    let orderlineId = this.shoppingCart.Orderlines.find(ol => ol.Id.includes('TEMP'))?.Id;
    try {
      await this.RemoveFromShoppingCart({
        orderlineId: orderlineId,
        shouldRemoveFromDb: false
      });
      console.log(orderlineId);
    } catch (error) {
      console.log(error);
    }
  }
  setAddress(address: any) {
    this.selectedAddress = address.Id;
  }
  closeDialog() {
    this.quantity = 0;
    this.selectedAddress = '';
    this.totalPrice = '$0.00';
    this.dialog = false;
  }
  canAddToCart() {
    return (
      this.isFormValid && !this.isLoading && this.$refs.addressSelector.isAddressValid()
    );
  }
  changeQuantity(newQuantity: number) {
    this.quantity = newQuantity;
  }

  async openDialog() {
    this.data = ConversionHelper.convertQuickDialog(this.item);
    await this.getItemDetails();
    setTimeout(() => {
      this.selectedAddress = this.$refs.addressSelector.getAddressId();
    }, 500);
    this.dialog = true;
  }

  /* Computed */
  /* Mounted */

  /* Created */
}
