
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import LeftDrawer from '../components/LeftDrawer.vue';
import ItemService from '@/services/item-service';
import { CustomerService } from '@/services/customer-service';
import ItemDialog from '@/components/ItemDialog.vue';
import ItemOrderDialog from '@/components/ItemOrderDialog.vue';
import ItemAddToCart from '@/components/ItemAddToCart.vue';
import ItemQuotesDialog from '@/components/ItemQuotesDialog.vue';
import ItemHoldDialog from '@/components/ItemHoldDialog.vue';
import ItemHistory from '@/components/ItemHistory.vue';
import ItemDeleteDialog from '@/components/ItemDeleteDialog.vue';
import ItemApprovalDialog from '@/views/ItemApproval.vue';
import ItemMergeDialog from './ItemMergeDialog.vue';
import ItemDownloadDialog from '@/components/ItemDownloadDialog.vue';
import ItemOrderProofDialog from '@/components/ItemOrderProofDialog.vue';
import ItemFileSuccessfulDialog from '@/components/ItemFileSuccessfulDialog.vue';
import ItemPartNumberEditDialog from '@/components/ItemPartNumberEditDialog.vue';
import AlertComponent from '@/components/AlertLayout.vue';
import { Item, ItemDetails } from '../store/models/ItemModel';
import {
  Orderline,
  OrderlineDetails,
  OrderlinePurchaseStateTypes,
  OrderPo,
  RushOption
} from '../store/models/OrderlineModel';
import { OrdersService } from '@/services/orders-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import AuthService from '@/services/auth-service';
import { Confirm } from '@/helpers/AuthHelper';
import { Customer } from '@/store/models/CustomerModel';
import { PaymentService } from '@/services/payment-service';
@Component({
  components: {
    LeftDrawer,
    ItemDialog,
    ItemAddToCart,
    ItemOrderDialog,
    ItemQuotesDialog,
    ItemApprovalDialog,
    ItemDownloadDialog,
    ItemHoldDialog,
    ItemDeleteDialog,
    ItemOrderProofDialog,
    AlertComponent,
    ItemFileSuccessfulDialog,
    ItemPartNumberEditDialog,
    ItemMergeDialog,
    ItemHistory
  }
})
export default class ItemActions extends Vue {
  $refs!: {
    ItemDialog: HTMLFormElement;
    ItemAddToCart: HTMLFormElement;
    ItemOrderDialog: HTMLFormElement;
    ItemQuotesDialog: HTMLFormElement;
    ItemHoldDialog: HTMLFormElement;
    ItemDownloadDialog: HTMLFormElement;
    ItemDeleteDialog: HTMLFormElement;
    ItemOrderProofDialog: HTMLFormElement;
    ItemFileSuccessfulDialog: HTMLFormElement;
    ItemPartNumberEditDialog: HTMLFormElement;
    ItemMergeDialog: HTMLFormElement;
    ItemHistory: HTMLFormElement;
  };

  @Getter('getDisplayStyle', { namespace: 'customer' })
  displayStyle!: string;
  @Getter('orderlines', { namespace: 'orderlines' })
  orderlines!: Orderline[];
  @Getter('isAdmin', { namespace: 'profile' })
  isAdmin!: Boolean;
  @Getter('getCustomer', { namespace: 'customer' })
  customer!: Customer;
  @Mutation('orderlinesLoaded', { namespace: 'orderlines' })
  setOrderlines!: any;
  @Mutation('addPOToList', { namespace: 'orderlines' })
  addPOToList!: any;

  @Watch('isProcessingImage')
  onIsProcessingImageChange() {
    const actionsToToggle = [1, 2, 3, 5, 6, 9];
    for (var action of this.actionItems) {
      if (actionsToToggle.includes(action.id)) {
        action.disabled = this.isProcessingImage;
      }
    }
  }

  @Prop({ required: true }) item!: Item;
  @Prop() isImageLoading!: boolean;
  @Prop() isProcessingImage!: boolean;
  /* Store Actions */

  itemApprovalRestrictedDialog: boolean = false;
  itemDetail: ItemDetails | null = null;
  po: string = '';
  filename: string = '';
  isLoading: boolean = false;
  actionItems: Array<{
    id: number;
    action: string;
    icon: string;
    hidden: boolean;
    disabled: boolean;
  }> = [
    {
      id: 1,
      action: 'Edit/Update',
      icon: 'mdi-pencil',
      hidden: false,
      disabled: false
    },
    {
      id: 2,
      action: this.item.IsApproved ? 'Place on Hold' : 'Approve Now',
      icon: this.item.IsApproved ? 'mdi-pause-circle-outline' : 'mdi-check-all',
      hidden: false,
      disabled: this.isProcessingImage || this.item.IsStaticItem
    },
    {
      id: 3,
      action: 'Order Now',
      icon: 'mdi-youtube-subscription',
      hidden: false,
      disabled: this.isProcessingImage
    },
    {
      id: 4,
      action: 'Quick Quote',
      icon: 'mdi-poll',
      hidden: false,
      disabled: false
    },
    {
      id: 5,
      action: 'Download',
      icon: 'mdi-cloud-download',
      hidden: false,
      disabled: this.isProcessingImage || this.item.IsStaticItem
    },
    {
      id: 6,
      action: 'Order a Proof',
      icon: 'mdi-file-search',
      hidden: false,
      disabled: this.item.IsStaticItem
    },
    {
      id: 7,
      action: 'Item History',
      icon: 'mdi-history',
      hidden: false,
      disabled: false
    },

    {
      id: 8,
      action: this.item.CanDelete ? 'Delete' : 'Disable',
      icon: 'mdi-delete-forever',
      hidden: false,
      disabled: false
    },
    {
      id: 10,
      action: 'Search Orders',
      icon: 'mdi-magnify',
      hidden: false,
      disabled: false
    }
  ];

  async handleEvent(id: number) {
    switch (id) {
      case 1:
        if (this.item.IsStaticItem) {
          this.$router.push({
            name: 'AddEditTransparencyItem',
            params: { id: this.item.Id }
          });
          return;
        }
        await this.GetItemDetails(this.item.Id);
        this.$refs.ItemDialog.openDialog();
        return;
      case 2:
        if (this.item.IsApproved) {
          this.$refs.ItemHoldDialog.openDialog();
        } else if (this.item.Die.Id === 212) {
          this.itemApprovalRestrictedDialog = true;
        } else {
          this.$router.push({
            name: 'ItemApproval',
            params: { id: this.item.Id }
          });
        }
        return;
      case 3:
        this.$refs.ItemOrderDialog.openDialog();
        return;
      case 4:
        this.$refs.ItemQuotesDialog.openDialog();
        return;
      case 5:
        await this.GetItemDetails(this.item.Id);
        this.$refs.ItemDownloadDialog.openDialog();
        return;
      case 6:
        this.$refs.ItemOrderProofDialog.openDialog();
        return;
      case 7:
        this.$refs.ItemHistory.openDialog();
        return;
      case 8:
        if (this.item.CanDelete) {
          this.$refs.ItemDeleteDialog.openDialog();
        } else {
          Confirm(
            () => {
              this.DisableItem();
            },
            'Are you sure?',
            `Are you sure you want to disable part ${this.item.PartNumber}?`
          );
        }
        return;
      case 9:
        this.$router.push(`barcodePlacement/${this.item.Id}`);
        return;
      case 10:
        this.$router.replace({
          name: 'Orders',
          params: {
            partnumber: this.item.PartNumber,
            description: this.item.Description
          }
        });
        return;
      case 11:
        Confirm(
          () => {
            this.RebuildHighRezImage();
          },
          'Are you sure?',
          `Are you sure you want to rebuild ${this.item.PartNumber}?`
        );
        return;
    }
  }
  openItemMergeDialog() {
    this.$refs.ItemPartNumberEditDialog.closeDialog();
    this.$refs.ItemMergeDialog.openDialog();
  }
  async placeOnHold() {
    SetSnackBar('Putting item on hold...');
    await this.$store.dispatch('items/PlaceOnHold', this.item.Id);
    await this.$store.dispatch('items/refreshItem', this.item.Id);
    if (this.$refs.ItemHoldDialog) this.$refs.ItemHoldDialog.closeDialog();
    SetSnackBar('Item is now on hold');
    this.actionItems[4].action = 'Approve Item';
  }
  async deleteItem(shouldDelete: boolean) {
    if (shouldDelete) {
      SetSnackBar('Deleting item...');
      this.$refs.ItemDeleteDialog.closeDialog();
      await this.$store.dispatch('items/DeleteItem', this.item.Id);
      SetSnackBar('Deleted Item successfully');
    }
  }
  async partNumberChanged() {
    SetSnackBar('Part Number Changed Successfully');
    this.$emit('removeDuplicateParts', this.item);
  }
  async editItem(response: any) {
    if (response.didChangePartNumber) {
      SetSnackBar('Part Number Changed Successfully');
      this.partNumberChanged();
    } else {
      SetSnackBar('Item updated successfully');
    }
    if (response.didAccept) {
      this.$refs.ItemDialog.closeDialog();
      let handleEditItem = { ...response, affectedItem: this.item };
      this.$emit('handleEditItem', handleEditItem);
    }
  }

  async orderProof(response: any) {
    SetSnackBar('Ordering your proof...');
    const axiosresponse = await ItemService.RequestPhysicalProof(response);
    await this.$store.dispatch('items/refreshItem', this.item.Id);
    const model = {
      Pos: [axiosresponse.data.PO],
      page: 0
    };
    const responseOrderline = await OrdersService.GetOrderlinesByPO(model);

    if (this.orderlines[0]) {
      const proofOrderline = await OrdersService.RefreshOrderline(
        responseOrderline.data[0].Id
      );
      const newOrderline: Orderline[] = [new Orderline(proofOrderline.data)];
      let orderlinesWithAddedOl = [...newOrderline, ...this.orderlines];
      this.setOrderlines(orderlinesWithAddedOl);
    }
    SetSnackBar('Proof(s) were placed successfully');
    this.$refs.ItemOrderProofDialog.closeDialog();
  }

  async refreshItemAndPo(po: string) {
    this.$store.dispatch('items/refreshItem', this.item.Id);
    await this.$store.dispatch('orderlines/ClearCurrentOrder');
    this.addPOToList(
      new OrderPo({
        PO: po,
        Page: 1
      })
    );
  }

  async orderItem(response: any) {
    SetSnackBar('Ordering this item...');
    this.isLoading = true;
    try {
      const { data } = await PaymentService.GetCustomerPaymentProfiles();
      const defaultPayment = data.filter((p: any) => p.IsDefault == true);
      let model = {
        Orderlines: response.orderline.map((ol: OrderlineDetails) =>
          ConversionHelper.convertOrderlineToAddModel(ol)
        ),
        PO: response.selectedPO,
        State:
          response.orderline[0].OrderlinePurchaseStateType ===
          OrderlinePurchaseStateTypes.Create
            ? 'New'
            : 'Edit',
        ShipToId: 4,
        PaymentMethodProfileId: defaultPayment[0].PaymentProfileId,
        OrderType: 'Order Now'
      };
      const orderResponse = await OrdersService.PlaceOrder(model);
      const newOrderline: Orderline[] = orderResponse.data.map(
        (r: any) => new Orderline(r)
      );
      this.$store.dispatch('items/refreshItem', this.item.Id);
      await this.$store.dispatch('orderlines/ClearCurrentOrder');
      this.addPOToList(
        new OrderPo({
          PO: response.selectedPO,
          Page: 1
        })
      );
      this.$refs.ItemOrderDialog.closeDialog();
      SetSnackBar('Item ordered successfully');
    } catch (err) {
      const error = err as any;
      if (
        error.response &&
        !error.response.data.ExceptionMessage.includes('Object reference')
      ) {
        SetSnackBar(error.response.data.ExceptionMessage);
      } else {
        SetSnackBar(
          'Issue has occured while placing your order. Please try again or contact technical support if issue persists'
        );
      }
    }
    this.isLoading = false;
  }

  async DisableItem() {
    SetSnackBar('Disabling item...');
    await this.$store.dispatch('items/DeleteItem', this.item.Id);
    SetSnackBar('Disabled Item successfully.');
  }

  async RebuildHighRezImage() {
    this.$emit('rebuildHighRezImage');
    SetSnackBar('Rebuilding High Rez Image...');
    await ItemService.RebuildHighRezImage(this.item.Id);
  }

  async GetItemDetails(itemId: string) {
    const response = await ItemService.GetItemDetails(itemId);
    if (response.data) {
      this.filename = response.data.FileName;
      this.itemDetail = response.data;
    }
  }
  openOrderProofDialog() {
    this.$refs.ItemOrderProofDialog.openDialog();
  }
  setShouldItemRefreshToTrue(response: boolean) {
    this.$emit('setShouldItemRefreshToTrue', response);
  }
  async mounted() {
    if (this.isAdmin) {
      this.actionItems.push({
        id: 11,
        action: 'Rebuild High Rez',
        icon: 'mdi-panorama-variant-outline',
        hidden: false,
        disabled: false
      });
    }
    if (this.customer && this.customer.Options?.CanUseAmazonVarData) {
      this.actionItems.push({
        id: 9,
        action: 'Barcode Placement',
        icon: 'mdi-data-matrix',
        hidden: false,
        disabled: this.isProcessingImage || this.item.IsStaticItem
      });
    }
  }
}
